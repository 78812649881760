import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { TextField, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SearchContext } from '../contexts/searchContext';
import { useNavigate, useLocation } from 'react-router-dom';

const SearchBar = () => {
    const {
        searchText, setSearchText,
        selectedLocation, setSelectedLocation,
        selectedJobType, setSelectedJobType,
        setFilteredJobs,
        loading, setLoading,setSelectedJob,
        setPage,setTotalPages,setHandleSearchCall
    } = useContext(SearchContext);

    const navigate = useNavigate();
    const location = useLocation();
    
    const [locations, setLocations] = useState([]);
    const [showLatestJobs, setShowLatestJobs] = useState(true); 
    const [showCompanies, setShowCompanies] = useState(true);  

    const jobTypes = [
        { label: "Full Time", value: 1 },
        { label: "Part Time", value: 2 },
        { label: "Internship", value: 3 },
        { label: "Contract", value: 4 }
    ];

    const handleSearch = async (newPage) => {
        if (location.pathname !== '/search') {
            navigate('/search');
        }
        console.log('home: handleSearch: Page = ', newPage);
        
        if (!searchText && !selectedLocation && !selectedJobType) {
            alert('Please fill at least one search criterion.');
            return;
        }
    
        setLoading(true);
        setFilteredJobs([]); 
        setSelectedJob(null); 
        setShowLatestJobs(false);
        setShowCompanies(false);
        
        const locationId = selectedLocation ? selectedLocation.id : null;
        const jobType = selectedJobType ? selectedJobType.value : null;
    
        // Reset to first page when searching
        const jobs = await fetchJobs(searchText, locationId, jobType, newPage);
        setFilteredJobs(jobs);
    
        if (jobs.length > 0) {
            setSelectedJob(jobs[0]);
        }
    
        setLoading(false);
        setPage(newPage);      
    };
    
    useEffect(() => {
        // Set the handleSearch function only once when the component mounts
        setHandleSearchCall(() => handleSearch);
    }, [setHandleSearchCall, handleSearch]);

    const fetchJobs = async (searchTerm, locationId, jobType, page = 1, pageSize = 10) => {
        try {
            // Start with the base URL
            let url = process.env.REACT_APP_API_URL+`/jobs?search=${encodeURIComponent(searchTerm)}&page=${page}&pageSize=${pageSize}`;
    
            // Append jobType if it is not null or undefined
            if (jobType !== null && jobType !== undefined) {
                url += `&jobType=${encodeURIComponent(jobType)}`;
            }
    
            // Append locationId if it is not null or undefined
            if (locationId !== null && locationId !== undefined) {
                url += `&location=${encodeURIComponent(locationId)}`;
            }
    
            // Fetch data from the API
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonResult = await response.json();
    
            // Update the total pages from API response
            setTotalPages(jsonResult.pagination.totalPages);
            return jsonResult.data; // Assuming jobs are returned under the 'jobs' key
        } catch (error) {
            console.error('Failed to fetch jobs:', error);
            return [];
        }
    };

    /*Search bar*/
    const fetchLocations = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL+ '/metadata/locations');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.map(loc => ({ id: loc.id, name: loc.name }));
        } catch (error) {
            console.error('Failed to fetch locations:', error);
            return [];
        }
    };

    useEffect(() => {
        const loadLocations = async () => {
            const locationData = await fetchLocations();
            setLocations(locationData);
        };

        loadLocations();
    }, []);

    return (
        <div className="sticky-search">
            <Container className="bg-white py-3 shadow-sm">
                <Row className="justify-content-center">
                    <Col>
                        <Form className="d-flex" style={{ width: '100%' }}>
                            <TextField
                                label="Search jobs"
                                variant="outlined"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ width: '40%', marginRight: '1%' }}
                            />
                            <Autocomplete
                                options={locations}  // Load your location options here
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                                value={selectedLocation}
                                onChange={(event, newValue) => setSelectedLocation(newValue)}
                                style={{ width: '20%', marginRight: '1%' }}
                            />
                            <Autocomplete
                                options={jobTypes}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <TextField {...params} label="Job Type" variant="outlined" />}
                                value={selectedJobType}
                                onChange={(event, newValue) => setSelectedJobType(newValue)}
                                style={{ width: '20%', marginRight: '1%' }}
                            />
                            <Button variant="primary" onClick={() =>  handleSearch(1)} disabled={loading} style={{ width: '19%' }}>
                                <SearchIcon />
                                {loading ? ' Searching...' : 'Search'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SearchBar;
