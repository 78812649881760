import React from 'react';
import { Link } from 'react-router-dom'; // Import Link to enable navigation

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>© 2024 GoRemoteNow.Co. All Rights Reserved.</p>
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
          &nbsp; | &nbsp;
          <Link to="/terms-of-use">Terms of Use</Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
