import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';  // Import your JobPortal component
import JobDetails from './components/jobDetailsComponent';
import SearchResults from './components/searchResultsComponent';
import PrivacyPolicy from './components/privacyPolicyComponent'; // Import the Privacy Policy component
import TermsOfUse from './components/termsOfUseComponent';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      {/* All routes are managed inside the App component */}
      <Routes>
        <Route path="/" element={<App />}> {/* Use App as the layout */}
          <Route index element={<Home />} />  {/* JobPortal as the main content */}
          <Route path="job/:jobId" element={<JobDetails />} /> {/* Dynamic JobDetails */}
          <Route path="search" element={<SearchResults />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy route */}
          <Route path="terms-of-use" element={<TermsOfUse />} /> {/* Privacy Policy route */}
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
