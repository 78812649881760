// JobList.js
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Form, Button, Card, Dropdown, Image } from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Pagination from 'react-bootstrap/Pagination';
import { SearchContext } from '../contexts/searchContext';

const JobList = ({ jobs, selectedJob, handleImageError }) => {
    const {
        searchText, setSearchText,
        selectedLocation, setSelectedLocation,
        selectedJobType, setSelectedJobType,
        setFilteredJobs,
        loading, setLoading,setSelectedJob ,
        totalPages,setPage,
        handleSearchCall,
        page
    } = useContext(SearchContext);

    const handlePageChange = (newPage) => {
        console.log('jobListComponent: handlePageChange: Page = ',newPage, ', handleSearchCall = ',handleSearchCall )
        setPage(newPage);
        handleSearchCall(newPage); // Fetch jobs for the new page
    };
    

    const getSalaryLabel = (maxSalary, currency, interval) => {
        if (!maxSalary || maxSalary <= 0.0) return null;
    
        const formattedSalary = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
        }).format(maxSalary);
    
        let shortCode = '';
        switch (interval) {
            case 'yearly':
                shortCode = '/yr';
                break;
            case 'monthly':
                shortCode = '/mo';
                break;
            case 'hourly':
                shortCode = '/hr';
                break;
            default:
                return null;
        }
        return `${formattedSalary}${shortCode}`;
    };
    const handleCardClick = (job) => {
        setSelectedJob(job);
        console.log('JobList: setSelectedJob: job = ',job)
    };
    // Helper function to calculate time ago
    const timeAgo = (dateString) => {
        const now = new Date();
        const postedDate = new Date(dateString);
        const diffInMs = now - postedDate;
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30);

        if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else {
            return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        }
    };

    const renderPagination = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            console.log('Page',page)
            items.push(
                <Pagination.Item key={number} active={number === page} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
        return <Pagination>{items}</Pagination>;
    };    

    return (
        <Col md={6}>
            {jobs.length ? (
                jobs.map((job) => (
                    <Card
                        key={job.id}
                        className={`mb-3 ${selectedJob?.id === job.id ? 'selected-card' : ''}`}
                        onClick={() => handleCardClick(job)}
                        style={{ cursor: 'pointer', position: 'relative' }}
                    >
                        <Card.Body className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <Image
                                    src={job.companyLogoUrl || '/images/default-company-logo.png'}
                                    rounded
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                    alt={`${job.company} logo`}
                                    onError={handleImageError}
                                    className="card-img"
                                />
                                <div>
                                    <Card.Title>{job.title}</Card.Title>
                                    <Card.Subtitle className="text-muted mb-1">{job.company}</Card.Subtitle>
                                    <Card.Text className="company-info">
                                        {`Employees: ${job.companyNumEmployees || 'N/A'}, Revenue: ${job.companyRevenue || 'N/A'}`}
                                    </Card.Text>
                                </div>
                            </div>
                            <div className="d-flex align-items-center text-muted">
                                <LocationOnIcon className="me-1" />
                                <span>{job.location}</span>
                                {job.maxSalary && (
                                    <>
                                        <span className="mx-2">•</span>
                                        <span>{getSalaryLabel(job.maxSalary, job.currency, job.salaryInterval)}</span>
                                    </>
                                )}
                            </div>
                            <div className="posting-date mt-auto">
                                {timeAgo(job.datePosted)}
                            </div>
                        </Card.Body>
                    </Card>
                ))
            ) : (
                <p></p>
            )}
            {totalPages > 1 && renderPagination()}
        </Col>
    );
};

export default JobList;
