import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // For navigation

const Header = () => {
  return (
    <header className="sticky-top bg-light py-3">
      <Container>
        <Row className="align-items-center justify-content-between">
          {/* Logo on the left */}
          <Col xs="auto">
            <Link to={`/`}>
              <img src="/images/logo.svg" alt="JobPortal Logo" style={{ height: '1.75rem' }} /> {/* Adjust height as needed */}
            </Link>                  
          </Col>

          {/* Right-side menu items */}
          <Col xs="auto">
            <nav>
              <ul className="list-unstyled d-flex mb-0">
                <li className="me-3">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="me-3">
                  <Link to="/privacy-policy" className="nav-link">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-use" className="nav-link">Terms of Use</Link>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>

      {/* Inline styles or a separate CSS file can be used for custom styling */}
      <style jsx>{`
        .nav-link {
          font-weight: bold;
          color: black;
          text-decoration: none; /* No underline */
        }

        .nav-link:hover {
          color: #007bff; /* Bootstrap primary color for hover */
          text-decoration: none; /* No underline on hover */
        }
      `}</style>
    </header>
  );
};

export default Header;
