import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // For navigation to full page job details
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Material icon for "Open in New"

const LatestJobs = ({ latestJobs, handleImageError }) => {
    return (
        <Container className="mt-4">
            <h2>Latest Jobs</h2>
            <Row>
                {latestJobs.map((job, index) => (
                    <Col md={4} key={index}>
                        <Card className="mb-3" style={{ cursor: 'pointer', position: 'relative' }}>
                            <Card.Body className="d-flex align-items-center">
                                {/* Company logo */}
                                <Image
                                    src={job.companyLogoUrl || '/images/default-company-logo.png'}
                                    rounded
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                    onError={handleImageError}
                                />
                                {/* Job Title and Company */}
                                <div>
                                    <Card.Title>{job.title}</Card.Title>
                                    <Card.Subtitle className="text-muted">{job.company}</Card.Subtitle>
                                    {/* Job posting age (assuming you have job age information) */}
                                    <Card.Text className="text-muted">
                                        Posted {job.age || 'recently'} {/* Assuming 'job.age' contains posting age */}
                                    </Card.Text>
                                </div>

                                {/* Open in New Tab icon */}
                                <Link to={`/job/${job.id}`} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                    <OpenInNewIcon fontSize="small" />
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default LatestJobs;
