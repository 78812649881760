import React from 'react';
import { Outlet } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/header'
import SearchBar from './components/searchBarComponent'
import './App.css';
import { SearchProvider } from './contexts/searchContext'; 
import Footer from './components/footer';

function App() {
  return (
    <SearchProvider>
      <div className="app-container">
      <Header/>
      <SearchBar />
        {/* Main body placeholder where content will be injected */}
        <main className="main-content">
          <Outlet /> {/* This is the placeholder body */}
        </main>
      </div>
      <Footer /> 
    </SearchProvider>
  );
}

export default App;
