// Companies.js
import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';

const Companies = ({ topCompanies, handleImageError }) => {
    return (
        <Container className="mt-4">
            <h2>Top Companies</h2>
            <Row>
                {topCompanies.map((company, index) => (
                    <Col md={4} key={index}>
                        <Card className="mb-3" style={{ cursor: 'pointer' }}>
                            <Card.Body className="d-flex align-items-center">
                                <Image
                                    src={company.companyLogoUrl}
                                    rounded
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
                                    onError={handleImageError}
                                />
                                <div>
                                    <Card.Title>{company.name}</Card.Title>
                                    <Card.Text className="mb-1">
                                        <strong>Revenue:</strong> {company.companyRevenue}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Employees:</strong> {company.companyNumEmployees}
                                    </Card.Text>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Companies;
